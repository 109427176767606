var api = require("!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../node_modules/css-loader/index.js?{\"localIdentName\":\"[path]___[name]__[local]___[hash:base64:5]\"}!../../../../../node_modules/postcss-loader/src/index.js?{\"sourceMap\":true,\"ident\":\"postcss\"}!./salaryExports.css");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};